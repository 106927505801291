import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";
import './App.css'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import axios from 'axios';
// Components
import ScrollToTopButton from "./Components/ScrollToTop"
import Appconfig from "./Constant/config";
const ConsultingPage = lazy(() => import("./Pages/Home/Consulting"))
function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState({})
  const [testimonial, setTestimonial] = useState([])
  const [id, setId] = useState([]);
  const [isHide, setIsHide] = useState()
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == '/fr') {
      i18n.changeLanguage('fr');
    }else{
      i18n.changeLanguage('en');
    }
  }, [location])

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

  }, [location]);
  const convertNameToKey = (name) => {
    return name.toLowerCase().replace(/\s+/g, '_');
  };
  const list = async () => {
    try {
      const result = await axios.get(`${Appconfig.baseurl}/website/list_data`);
      setIsHide(result.data.data)
      const englishTextData = await result.data.data.reduce((acc, item) => {
        acc[convertNameToKey(item.name)] = item.text;
        setTestimonial(acc)
        return acc;
      }, {});
      const images1 = await result.data.data.reduce((acc, item) => {
        acc[convertNameToKey(item.name)] = item.images;
        return acc;
      }, {});
      setImages(images1)
      await i18n.addResourceBundle('en', 'translation', englishTextData);

      const frenchTextDataObject = await result.data.data.reduce((acc, item) => {
        acc[convertNameToKey(item.name)] = item.french_text;
        return acc;
      }, {});

      i18n.addResourceBundle('fr', 'translation', frenchTextDataObject);
      setId(frenchTextDataObject)
      console.log("Translations loaded successfully", englishTextData, images);
    } catch (error) {
      console.error("Error fetching or loading translations", error);
    }
  };
  useEffect(() => {
    list()
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <I18nextProvider i18n={i18n}>
              <ScrollToTopButton />
              <AnimatePresence exitBeforeEnter>
                <Suspense fallback={<></>}>
                  <Routes>
                    <Route path="/fr" element={<ConsultingPage frenchLan={true} testimonialKey={testimonial.testimonials} dataForHide={isHide} images={images} style={{ "--base-color": "#bf8c4c" }} />} />
                    <Route path="/" element={<ConsultingPage frenchLan={false} testimonialKey={testimonial.testimonials} dataForHide={isHide} images={images} style={{ "--base-color": "#bf8c4c" }} />} />
                    <Route path="/:any" element={<ConsultingPage frenchLan={false} testimonialKey={testimonial.testimonials} dataForHide={isHide} images={images} style={{ "--base-color": "#bf8c4c" }} />} />
                  </Routes>
                </Suspense>
              </AnimatePresence>
            </I18nextProvider>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;

