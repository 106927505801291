// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
// import esTranslation from './locales/es.json';
import frTranslation from './locales/fr.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  // es: {
  //   translation: esTranslation,
  // },
  fr: {
    translation: frTranslation,
  },
};

i18n
  .use(initReactI18next)
  .init({
    // resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
